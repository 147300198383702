/*
    Fonts
 */
@text-h 1--lead {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 100;
  line-height: 1.25em;
}
@text-h 1 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.25em;
}
@text-h 2 {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.25em;
}
@text-h 3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25em;
}
@text-h 4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25em;
}
@text-h 5 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25em;
}
@text-h 6 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.25em;
}
@text-body--lead {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25em;
}
@text-body {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.25em;
}
@text-body--small {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.25em;
}
@text-body--caption {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.25em;
}
@text-button {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 1.25px;
  line-height: 1.25em;
}
@text-blockquote {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25em;
}
@text-fieldset--legend {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25em;
}
@text-field--label {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.25em;
}
/*
    Colour Palette
 */
.stats-upgrade-banner {
  align-items: center;
  background-color: #F5F6F9;
  display: flex;
  font-size: 1.25em;
  font-weight: bold;
  padding: 10px;
}
.metrics-funnel {
  background-color: #F5F6F9;
  overflow: hidden;
  position: relative;
}
.metrics-funnel__placeholder-number {
  display: block;
  margin-left: -1em;
  margin-top: -1em;
}
.metrics-funnel__placeholder-demos {
  display: block;
  margin-left: -2em;
  margin-top: -3em;
  overflow: hidden;
  width: 100%;
  height: 150px;
}
.metrics-funnel__step-wrapper {
  flex-grow: 1;
  flex-basis: 100%;
}
.metrics-funnel__step-wrapper:first-child {
  flex-basis: calc(110%);
}
.metrics-funnel__step-wrapper:last-child {
  flex-basis: calc(90%);
}
.metrics-funnel__step {
  flex: 1 1 auto;
  height: 100%;
  padding: 10px 20px 10px;
  position: relative;
}
.metrics-funnel__arrow {
  flex: 0 0 auto;
  width: 40px;
}
.metrics-funnel__arrow::before {
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 40px solid white;
  content: " ";
  display: block;
  height: 0;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}
.metrics-funnel__arrow::after {
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 40px solid #F5F6F9;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}
.metrics-funnel__value {
  font-size: 42px;
  line-height: 1;
  color: #475771;
}
@media (max-width: 768px) {
  .metrics-funnel__value {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .metrics-funnel__value {
    text-align: center;
  }
}
.metrics-funnel__label {
  border-bottom: 1px dashed #206BEE;
  cursor: pointer;
  cursor: help;
  display: inline-block;
  position: relative;
  z-index: 2;
}
.gl-partner-metrics-step__label {
  text-transform: uppercase;
  font-weight: bold;
}
[title]:hover::after,
[title]:focus::after,
[title]:focus-within::after {
  content: attr(title);
  position: absolute;
  border: 1px solid silver;
  background: white;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  color: white;
  background-color: black;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  width: 180px;
  top: -5px;
  left: 105%;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
