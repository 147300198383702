.preview-post {
  width: 100%;
}
.preview-post__layout {
  display: flex;
}
.preview-post__greyed-text {
  background-color: #ccc;
  width: 140px;
  height: 12px;
  margin-top: 5px;
}
.preview-post__greyed-pic {
  background-color: #ccc;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.preview-post__greyed-button {
  background-color: #ccc;
  width: 80px;
  height: 22px;
  margin-left: auto;
}
.preview-post__greyed-link {
  background-color: #ccc;
  width: 285px;
  height: 12px;
  margin-top: 10px;
}
.preview-post__offer-name {
  font-size: 18px;
  font-weight: 500;
}
.preview-post__border {
  border: 1px solid #ccc;
}
.preview-post__border-large {
  border: 1px solid #ccc;
  padding: 15px;
}
.preview-post__padding {
  padding: 10px;
}
.preview-post__font-sm {
  font-size: 12px;
}
.preview-post__page-title {
  font-size: 14px;
  font-weight: 700;
  color: #365899;
}
.preview-post__margin-sm {
  margin-top: 5px;
}
