.week-day-selector__day {
  border: 1px solid #ccc;
}
.week-day-selector__day:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.week-day-selector__day:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.week-day-selector__day + .week-day-selector__day {
  border-left: none;
}
