.c-pin-table__header,
.c-pin-table__header-col {
  font-size: 0.8em;
}
.c-pin-table__pin {
  font-family: monospace;
  font-size: 1.2em;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
