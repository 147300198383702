.c-print-preview {
  display: none;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: white;
  top: 0;
  left: 0;
}
@media print {
  .c-print-preview {
    display: block;
  }
}
